<script setup>
import { useDisplay } from "vuetify";
defineProps({ blok: Object });

const display = useDisplay();

let startScrollPositionSet = false; // Flag, um zu markieren, ob der Startpunkt bereits festgelegt wurde
let initHolderRelativeTop = null; // Der relative Abstand des Holders zur oberen Kante des Viewports beim ersten Aufruf
const handleScroll = () => {
  parallax();

  const gradientBoxWrap = document.querySelector(".hero-gradient-box-wrap");
  const productConatainerBoxWrap = document.querySelector(
    ".product-container .content-holder",
  );
  const referenceBoxWrap = productConatainerBoxWrap || gradientBoxWrap;

  const offset = productConatainerBoxWrap ? 400 : 0;

  const startWidth = 110;
  const endWidth = 90;
  const startOpacity = 0.5;
  const endOpacity = 1;

  if (referenceBoxWrap) {
    const bounding = referenceBoxWrap.getBoundingClientRect();

    const isInView =
      bounding.bottom - offset <=
      (window.innerHeight || document.documentElement.clientHeight);

    if (isInView) {
      // Calculate the scroll position relative to 100px
      const maxScroll = window.innerHeight / 2;
      const currentScroll = window.scrollY - bounding.height;

      // Clamp the scroll value between 0 and 100
      const clampedScroll = Math.min(maxScroll, Math.max(0, currentScroll));

      // Calculate the width based on the clamped scroll value

      const width =
        startWidth - (startWidth - endWidth) * (clampedScroll / maxScroll);

      // Calculate the opacity based on the clamped scroll value

      const opacity =
        startOpacity +
        (endOpacity - startOpacity) * (clampedScroll / maxScroll);

      // Apply the calculated opacity to the element
      gradientBoxWrap.style.opacity = opacity;

      // Apply the calculated width to the element
      gradientBoxWrap.style.width = `${width}%`;
      gradientBoxWrap.style.opacity = opacity;
    } else {
      gradientBoxWrap.style.width = `${startWidth}%`;
      gradientBoxWrap.style.opacity = startOpacity;
    }
  }
};

function parallax() {
  const dashboardHolder = document.querySelector(".product-phone-image-holder");
  const dashboardWrap = document.querySelector(".product-dashboard-2-wrap");
  const dashboard2 = document.querySelector(".product-dashboard._2");

  if (dashboardHolder && dashboardWrap && dashboard2) {
    const holderRect = dashboardHolder.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // Berechnung der Startposition beim Erreichen von holderRect.top <= 70
    if (holderRect.top <= 70 && !startScrollPositionSet) {
      const holderTopAtStart = 70; // Der Punkt, an dem die Animation starten soll
      const relativeTopPosition = holderTopAtStart - holderRect.top; // Relativer Abstand von der aktuellen Position zur Startposition
      startScrollPositionSet = true; // Markieren, dass die Startposition festgelegt wurde
      // Einstellen der Startposition unter Berücksichtigung des relativen Abstandes
      window.startScrollPosition = initHolderRelativeTop - relativeTopPosition;
    }

    // Prüfen, ob der Startpunkt gesetzt wurde und Animation ausführen
    if (startScrollPositionSet) {
      const dashboard2Height = dashboard2.offsetHeight;
      const endScrollPosition = window.startScrollPosition + dashboard2Height;
      const scrollExtent = Math.max(
        0,
        Math.min(
          1,
          (scrollTop - window.startScrollPosition) /
            (endScrollPosition - window.startScrollPosition),
        ),
      );

      console.log({
        scrollExtent,
        dashboard2Height,
        endScrollPosition,
        scrollTop,
        startScrollPosition,
      });
      // Neue translateY Werte basierend auf scrollExtent berechnen
      const newTranslateYWrap = 100 - 100 * scrollExtent; // von 100% auf 0%
      const newTranslateY2 = -100 + 100 * scrollExtent; // von -100% auf 0%

      // Transform Eigenschaften anwenden
      dashboardWrap.style.transform = `translate3d(0px, ${newTranslateYWrap}%, 0px)`;
      dashboard2.style.transform = `translate3d(0px, ${newTranslateY2}%, 0px)`;
    } else {
      dashboardWrap.style.transform = "translate3d(0px, 100%, 0px)";
      dashboard2.style.transform = "translate3d(0px, -100%, 0px)";
    }
  }
}

onMounted(() => {
  initHolderRelativeTop = document
    .querySelector(".product-phone-image-holder")
    ?.getBoundingClientRect()?.top;
  handleScroll();
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  handleScroll();
  window.removeEventListener("scroll", handleScroll);
});
</script>

<template>
  <div v-editable="blok" class="main-section-hero">
    <BgHomeGradient v-if="!blok?.no_gradient" />

    <div
      :class="{
        'container no-padding': blok.image?.filename,
        'hero-main-container': !blok.image?.filename,
      }"
    >
      <div
        :class="{
          'flex-none md:flex flex-row': blok.image?.filename,
          'hero-small-container': !blok.image?.filename,
        }"
      >
        <div
          class="hero-01-content"
          :class="{
            'text-left w-full md:w-1/2 ml-5 mt-5': blok.image?.filename,
            'text-center': !blok.image?.filename,
          }"
          style="max-width: 690px"
        >
          <HeroHeader :blok>
            <slot name="header" />

            <template #buttons>
              <slot name="buttons" />
            </template>
          </HeroHeader>
        </div>
        <div
          v-if="blok?.image?.filename"
          class="w-full md:w-1/2 flex items-end"
        >
          <NuxtImg
            sizes="sm:200px md:300px lg:500px"
            :src="blok.image.filename"
            :alt="blok.image.alt"
            class="keyvisual-image mx-auto"
            :class="{
              'self-center mb-5 md:mb-0': blok.image_centered,
              'rounded rounded-lg w-100': blok.image_rounded,
            }"
          />
        </div>
      </div>
    </div>

    <div
      v-if="blok.blocks?.length"
      class="home-hero-container"
      :class="{ 'my-10': blok.space_between_contents && display.mdAndUp.value }"
      data-animate="fadeIn"
      data-animate-delay="3"
    >
      <div
        data-w-id="d6116216-2018-c43f-c07a-82f001922b4d"
        class="hero-product-demo"
        :class="{ 'with-sticky-product-demo': blok.sticky }"
      >
        <HeroProductDemo logos :sticky="blok.sticky">
          <StoryblokComponent
            v-for="subBlok in blok.blocks"
            :key="subBlok._uid"
            :blok="subBlok"
            :parent-blok="blok"
          />
        </HeroProductDemo>
      </div>
    </div>

    <slot />
  </div>
</template>

<style scoped lang="scss">
.main-section-hero {
  z-index: 0;
}
.product-container {
  padding-bottom: 150px;
}
.hero-product-demo {
  &:not(.with-sticky-product-demo) {
    height: auto;
  }
  &.with-sticky-product-demo {
    height: 200vh;
  }
}
.home-hero-container {
  height: auto;
}
.keyvisual-image {
  max-height: 460px;
  object-fit: contain;
  object-position: center bottom;
  width: 100%;
  height: 100%;

  &.w-100 {
    max-height: 500px;
    object-position: center;
  }
}
</style>
